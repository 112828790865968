<template>
  <v-chart
    class="chart-container"
    :option="option"
    style="width:100%!important;height:400%"
    autoresize
    :loading="isLoading"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent
} from "echarts/components";
import VChart from "vue-echarts";
import { mapGetters } from "vuex";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
]);

export default {
  data: () => ({
    option: {
      animationDuration: 1000,

      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
        splitLine: {
          show: false
        }
      },

      yAxis: {
        axisLine: { onZero: false },

        type: "value",
        splitLine: {
          show: true
        }
      },
      title: {
        left: "center",
        top: "center"
      },
      // dataZoom: [
      //   {
      //     type: "inside"
      //   }
      // ],
      tooltip: {
        axisPointer: {
          type: "line"
        },
        trigger: "axis"
      },
      grid: {
        top: "3%",
        bottom: "6%",
        left: "5%",
        right: "3%"
      },
      series: [
        {
          data: [],
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 10,
          color: "#3874CB",
          lineStyle: {
            width: 4
          },
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          },
          areaStyle: {
            color: "#3873cb78"
          }
        }
      ]
    }
  }),
  mounted() {
    if (this.type === "month") {
      this.option.xAxis.boundaryGap = true;
      this.option.tooltip.axisPointer.type = "shadow";
      this.option.series[0].type = "bar";
    }
  },
  components: {
    VChart
  },
  watch: {
    chartData: {
      deep: true,
      handler(e) {
        if (e) {
          const data = e?.datasets[0]?.data;
          const labels = e.labels;
          this.option.xAxis.data = labels;
          this.option.series[0].data = data;
          if (!data.length) {
            this.option.title.text = this.$t("cabinet.noData");
          } else {
            this.option.title.text = "";
          }
        }
      }
    },
    currentLanguage() {
      const data = this.chartData?.datasets[0]?.data;
      if (!data.length) {
        this.option.title.text = this.$t("cabinet.noData");
      } else {
        this.option.title.text = "";
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"])
  },
  props: {
    isLoading: {
      require: true,
      type: Boolean
    },
    type: {
      require: true
    },
    chartData: {
      require: true
    }
  }
};
</script>

<style lang="scss"></style>
