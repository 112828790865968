<template>
  <VCard class="my-2">
    <VCol class="pb-0">
      <VBtn
        text
        color="primary"
        :small="$vuetify.breakpoint.md"
        :x-small="$vuetify.breakpoint.smAndDown"
      >
        <VIcon left :small="$vuetify.breakpoint.md" :x-small="$vuetify.breakpoint.smAndDown">
          mdi-table-check
        </VIcon>
        {{ $t("cabinet.tableDeliveredItemsTitle") }}
      </VBtn>
    </VCol>
    <VCardText>
      <v-simple-table class="my-table" :dense="!isLarge" v-if="items.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.photo") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header d-block">
                  <span>{{ $t("table.name") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                   <span>{{ $t("table.barCode") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                   <span>{{ $t("table.amount") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                   <span>{{ $t("products.capacity") }} {{ '('}} {{$t('products.meter') }}<sup>3</sup>{{ ')' }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <tr v-for="(product, index) in items" :key="index">
              <td class="x-sm">
                <img
                  v-if="!product.path_img"
                  :src="require('@/assets/images/supply-item.png')"
                  alt=""
                  class="image-placeholder"
                  :class="{ sm: !isLarge }"
                />
                <img
                  v-else
                  :src="product.path_img"
                  class="image"
                  @click="showImgDialog(product)"
                  v-show="isImgLoaded"
                  @load="showImg"
                  :class="{ sm: !isLarge }"
                />
                <template v-if="product.path_img && !isImgLoaded">
                  <VRow class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      :size="isLarge ? '20' : '10'"
                      :width="isLarge ? '2' : '1'"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </VRow>
                </template>
              </td>
              <td class="text-left">
                {{ product.name || "--" }}
              </td>
              <td class="text-center copyTxt" @click="copyToClipboard(product.bar_code)">
                {{ product.bar_code || "--" }}
              </td>
              <td class="text-center">
                {{ product.amount || "--" }}
              </td>
              <td class="text-center">
                {{ getCapacityDelivered[index] || "--" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="text-center" :class="{ 'text-caption ': $vuetify.breakpoint.xs }">
        {{$t('cabinet.noShipmentItems')}}
      </div>
      <VCol cols="12" class="pa-0 ma-0">
        <VDivider :class="{'mt-4' :$vuetify.breakpoint.xs}"></VDivider>
      </VCol>
      <VRow class="size-hours text-right pr-4" align="center">
        <VCol cold="auto"  class="pr-0 pl-0"  :class="{'pb-0' :$vuetify.breakpoint.xs} ">
          <div  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('table.amount')}}:</div>
        </VCol>
        <VCol cols="auto" :class="{'pb-0' :$vuetify.breakpoint.xs}">
          <div class="size-hours-price"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalAmountDelivered || "0" }} шт.</div>
        </VCol>
        <VCol cols="1" class="pr-0 "  :class="{'pb-0' :$vuetify.breakpoint.xs} ">
          <div class="size-hours-header"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('products.capacity')}}:</div>
        </VCol>
        <VCol cols="auto" :class="{'pb-0' :$vuetify.breakpoint.xs}">
          <div class="size-hours-price"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{getTotalCapacityDelivered  || "0" }} {{ '('}} {{$t('products.meter') }}<sup>3</sup>{{ ')' }}</div>
        </VCol>
      </VRow>
    </VCardText>
    <VCol class="pb-0">
      <VBtn
        text
        color="primary"
        :small="$vuetify.breakpoint.md"
        :x-small="$vuetify.breakpoint.smAndDown"
      >
        <VIcon left :small="$vuetify.breakpoint.md" :x-small="$vuetify.breakpoint.smAndDown">
          mdi-table-arrow-left
        </VIcon>
        {{ $t("cabinet.tableReceivedItems") }}
      </VBtn>
    </VCol>
    <VCardText>
      <v-simple-table class="my-table" :dense="!isLarge" v-if="itemsReceived.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.photo") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header d-block">
                  <span>{{ $t("table.name") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                   <span>{{ $t("table.barCode") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                   <span>{{ $t("table.amount") }}</span>
                </div>
              </th>
              <th class="text-center">
                <div class="header">
                  <span>{{ $t("products.capacity") }} {{ '('}} {{$t('products.meter') }}<sup>3</sup>{{ ')' }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <tr v-for="(product, index) in itemsReceived" :key="index">
              <td class="x-sm">
                <img
                  v-if="!product.path_img"
                  :src="require('@/assets/images/supply-item.png')"
                  alt=""
                  class="image-placeholder"
                  :class="{ sm: !isLarge }"
                />
                <img
                  v-else
                  :src="product.path_img"
                  class="image"
                  @click="showImgDialog(product)"
                  v-show="isImgLoaded"
                  @load="showImg"
                  :class="{ sm: !isLarge }"
                />
                <template v-if="product.path_img && !isImgLoaded">
                  <VRow class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      :size="isLarge ? '20' : '10'"
                      :width="isLarge ? '2' : '1'"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </VRow>
                </template>
              </td>
              <td class="text-left">
                {{ product.name || "--" }}
              </td>
              <td class="text-center copyTxt" @click="copyToClipboard(product.bar_code)">
                {{ product.bar_code || "--" }}
              </td>
              <td class="text-center">
                {{ product.amount || "--" }}
              </td>
              <td class="text-center">
                {{ getCapacityReceived[index] || "--" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="text-center" :class="{ 'text-caption ': $vuetify.breakpoint.xs }">
        {{$t('cabinet.noShipmentItems')}}
      </div>
      <VCol cols="12" class="pa-0 ma-0">
        <VDivider :class="{'mt-4' :$vuetify.breakpoint.xs}"></VDivider>
      </VCol>
      <VRow justify="end" no-gutters >
        <VCol cols="12" >
          <VRow class="size-hours text-right pr-4" align="center">
            <VCol cold="auto" class="pr-0 "  :class="{'pb-0' :$vuetify.breakpoint.xs} ">
              <div class="size-hours-header"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('table.amount')}}:</div>
            </VCol>
            <VCol cols="auto" :class="{'pb-0' :$vuetify.breakpoint.xs}">
              <div class="size-hours-price"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalAmountReceived || "0" }} шт.</div>
            </VCol>
            <VCol cols="1" class="pr-0 pl-0"  :class="{'pb-0' :$vuetify.breakpoint.xs} ">
              <div  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('products.capacity')}}:</div>
            </VCol>
            <VCol cols="auto" :class="{'pb-0' :$vuetify.breakpoint.xs}">
              <div class="size-hours-price"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalCapacityReceived  || "0" }} {{ '('}} {{$t('products.meter') }}<sup>3</sup>{{ ')' }}</div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCardText>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </VCard>
</template>

<script>
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import metricService from "../../services/request/metrics/metricsService";
import { uniqueByUUID } from "../../mixins/helpers";
import loader from "../../mixins/loader";
import SizeUi from "../../mixins/SizeUi";
import imgDialog from "@/components/products/imgDialog.vue";

export default {
  components: {
    imgDialog
  },
  name: "ItemList",
  mixins: [user, notifications, loader, SizeUi],
  data: () => ({
    imgDialog: false,
    items: [],
    totalAmountDelivered: "",
    totalCapacityDelivered: "",
    itemsReceived: [],
    totalAmountReceived: "",
    totalCapacityReceived: "",
    createDialog: false,
    imgpath: [],
    isImgLoaded: false,
    year: new Date().toISOString().substr(0, 4),
    month: new Date().toISOString().substr(5, 2),
  }),
  props: ["chosenDate"],
  mounted() {
    this.getItems();
  },
  watch: {
    chosenDate: {
      deep: true,
      handler() {
        this.onSelectDate();
      }
    }
  },
  methods: {
    showImgDialog(product) {
      this.imgpath = product.path_img;
      // orderDialog(this.imgpath);
      this.imgDialog = true;
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    async onSelectDate() {
      try {
        this.setLoading(true);
        if (this.chosenDate) {
          this.year = this.chosenDate.substr(0, 4);
          this.month = this.chosenDate.substr(5, 2);
          this.getItems();
        }
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async getItems() {
      try {
        const params = [];
        // orderDialog(this.chosenDate);
        params.year = this.year;
        params.month = this.month;
        const newItems = await metricService.getMetricsItems(params);
        this.items = newItems;

        const newItemsReceived = await metricService.getMetricsItemsReceived(params);
        this.itemsReceived = newItemsReceived;

        // orderDialog(this.items.length);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    getCapacityReceived (){
      return this.itemsReceived.map(product => ((product.volume / 1000000) * product.amount).toFixed(3));
    },
    getCapacityDelivered (){
      return this.items.map(product => ((product.volume / 1000000) * product.amount).toFixed(3));
    },
    getTotalAmountReceived (){
      this.totalAmountReceived = this.itemsReceived.reduce(function (sum, item) {
        const amount = parseFloat(item.amount) || 0;
        return sum + amount;
      }, 0);
      return this.totalAmountReceived;
    },
    getTotalAmountDelivered (){
      this.totalAmountDelivered = this.items.reduce(function (sum, item) {
        const amount = parseFloat(item.amount) || 0;
        return sum + amount;
      }, 0);
      return this.totalAmountDelivered;
    },
    getTotalCapacityReceived (){
      this.totalCapacityReceived = this.itemsReceived.reduce(function (sum, item) {
        const amount = parseFloat(item.amount) || 0;
        const capacity = parseFloat(item.volume) || 0;
        const receivedCapacity = (amount * capacity) / 1000000;

        const roundedReceivedCapacity = parseFloat(receivedCapacity.toFixed(3));

        const roundedSum = parseFloat((sum + roundedReceivedCapacity).toFixed(3));

        return roundedSum;
      }, 0).toFixed(3);

      if (Number.isNaN(parseFloat(this.totalCapacityReceived))) {
        this.totalCapacityReceived = "0.000";
      }
      return this.totalCapacityReceived;
    },
    getTotalCapacityDelivered (){
      this.totalCapacityDelivered = this.items.reduce(function (sum, item) {
        const amount = parseFloat(item.amount) || 0;
        const capacity = parseFloat(item.volume) || 0.000;
        const deliveryCapacity = (amount * capacity) / 1000000;

        const roundedDeliveryCapacity = parseFloat(deliveryCapacity.toFixed(3));

        let roundedSum = parseFloat((sum + roundedDeliveryCapacity).toFixed(3));

        if(roundedSum == 0) {
          roundedSum = 0.000;
          return roundedSum;
        }

        return roundedSum;
      }, 0).toFixed(3);
      if (Number.isNaN(parseFloat(this.totalCapacityDelivered))) {
        this.totalCapacityDelivered = "0.000";
      }
      return this.totalCapacityDelivered;
    },
  }
};
</script>

<style lang="scss">
.image,
.image-placeholder {
  display: flex;
  align-items: center;
  object-fit: cover;
  border-radius: 5px;
  width: 60px;
  height: 50px;
  margin: 5px auto;

  transition: 0.3s ease;
  &-placeholder {
    border: 1px solid #e4e4e4;
  }
  &.sm {
    width: 30px;
    height: 25px;
  }
}
.image {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>
