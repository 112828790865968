import requestService from '../requestService';

const prefix = '/metrics';
export default {
  async getMetricsDeliveriesStates() {
    const response = await requestService.get(`${prefix}/deliveries/states`);
    return response?.data?.object;
  },
  async getMetricsDeliveriesDelivered(params) {
    if(localStorage.getItem('app_access_token')){
        if(params.uuid_user){
          const response = await requestService.get(`${prefix}/deliveries/daily_count?id_state=${params?.id_state}&year=${params?.year}&month=${params?.month}&uuid_user=${params?.uuid_user}`);
          return response?.data
        }else{
          const response = await requestService.get(`${prefix}/deliveries/daily_count?id_state=${params?.id_state}&year=${params?.year}&month=${params?.month}`);
          return response?.data
        }
  }
  },
  async getMonthMetricsDeliveriesDelivered(params) {

      const response = await requestService.get(`${prefix}/deliveries/monthly_count?id_state=${params?.id_state}&year=${params?.year}`);
      return response?.data
  },
  async getMetricsDeliveriesStatus() {
    const response = await requestService.get(`${prefix}/deliveries/status`);
    return response?.data?.object;
  },
  async getMetricsDeportSizeHours(params) {
    if(params.uuid_user){
      const response = await requestService.get(`${prefix}/deport/size/hours?year=${params?.year}&month=${params?.month}&uuid_user=${params?.uuid_user}`);
      return response?.data.object;
    }else{
      const response = await requestService.get(`${prefix}/deport/size/hours?year=${params?.year}&month=${params?.month}`);
      return response?.data.object;
    }
  },
  async getMetricsItems(params) {
    const response = await requestService.get(`${prefix}/products/sent_to_client?year=${params?.year}&month=${params?.month}`);
    return response?.data.object;
  },
  async getMetricsItemsReceived(params) {
    const response = await requestService.get(`${prefix}/products/received_by_client?year=${params?.year}&month=${params?.month}`);
    return response?.data.object;
  },
  async getMetricsDeportSizeCurrent() {
    const response = await requestService.get(`${prefix}/deport/size/current`);
     return response?.data.object;
  },
  async getMetricsDeportSizeCurrentForClient(uuid) {
    const response = await requestService.get(`${prefix}/deport/size/current?uuid_user=` + uuid);
     return response?.data.object;
  },
  async getMetricsDeliveriesIn(params) {
    const response = await requestService.get(`${prefix}/deliveries/daily/in/statistic`, params);
    return response?.data.object;
  },
  async getMetricsDeliveriesOut(params) {
    const response = await requestService.get(`${prefix}/deliveries/daily/out/statistic`, params);
    return response?.data.object;
  },
  async getMonitorData(params) {
    const response = await requestService.post(`/ns/deport/monitor`, params);
    return response?.data.object;
  }
};
