<template>
  <div>
    <VCard class="mb-2">
      <VCardTitle>
        <VRow
          :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''
            ]"
        >
          <VCol class="custom-v-col py-0">
            <VCol cols="auto">
              {{ $t("menu.newSend_your_online_deport") }}
            </VCol>
          </VCol>
          <VCol cols="11" xl="4" lg="4" md="4" sm="11" class="py-0">
            <DateSortedField v-if="showData" @onChangeDate="onChangeDate" />
          </VCol>
        </VRow>
      </VCardTitle>
    </VCard>
    <VCard style="height: 80vh" v-if="!showData">
      <div class="d-flex align-center justify-content-center" style="height:inherit">
        <img style="filter: blur(0.6px);" src="@/assets/images/no_data_img.svg" alt="" />
      </div>
    </VCard>
    <VRow no-gutters>
      <VCol cols="12" class="pr-0" xl="4" lg="4" md="12" sm="12">
        <CurrentSize class="mb-2" v-if="permissions.can_see_sizy_data" />
        <VCard v-if="showData" class="pb-3">
          <VCol cols="auto" style="height:60px">
            <VBtn text color="primary" :small="$vuetify.breakpoint.mdAndDown">
              <v-icon left :small="$vuetify.breakpoint.mdAndDown">
                mdi-all-inclusive
              </v-icon>
             {{$t('cabinet.shippingStatus')}}
            </VBtn>
          </VCol>
          <VCol
            v-if="metricsStates.length"
            class="cabinet-del-state-list"
            :class="{ clientCabinet: permissions.can_see_sizy_data }"
          >
            <div class="section-metricsStates">
              <div class="section-metricsStates__list">
                <div class="item" v-for="(item, index) in metricsStates" :key="index">
                  <div
                    class="item__content"
                    :class="{ active: Number(item.id_state) === id_state }"
                    @click="id_state = Number(item.id_state)"
                  >
                    <div
                      class="item__name unselectable d-flex align-center"
                      :class="{ 'text-caption': $vuetify.breakpoint.xs }"
                    >
                      <package-down-icon v-if="item.stateType === 'in'" />
                      <package-up-icon v-else />
                      {{ item?.[`${currentLanguage.key}_name`] || item?.name || '' }}
                    </div>
                    <div class="item__count unselectable" :class="{ sm: $vuetify.breakpoint.md }">
                      {{ item.count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VCol>
        </VCard>
      </VCol>

      <VCol
        v-if="showData"
        :class="$vuetify.breakpoint.mdAndDown ? ' pt-2' : 'pl-2'"
        cols="12"
        xl="8"
        lg="8"
        md="12"
        sm="12"
      >
        <VCard class="pa-3 pt-0 metric-card" elevation="1">
          <div v-if="!permissions.can_see_month_chart">
            <VRow align="center" justify="space-between" no-gutters class="pt-2">
              <VCol cols="auto">
                <VBtn
                  text
                  color="primary"
                  :small="$vuetify.breakpoint.md"
                  :x-small="$vuetify.breakpoint.smAndDown"
                >
                  <v-icon
                    left
                    :small="$vuetify.breakpoint.md"
                    :x-small="$vuetify.breakpoint.smAndDown"
                  >
                    mdi-finance
                  </v-icon>
                  {{$t('cabinet.shipmentsByDay')}}
                </VBtn>
              </VCol>
            </VRow>

            <DeliveredProducts
              @total="onTotalSend"
              :chosenDate="chosenDate"
              :id_state="id_state"
              :type="'day'"
            ></DeliveredProducts>
          </div>
          <div v-else>
            <ManagersChart :chosenDate="chosenDate" :id_state="id_state" @total="onTotalSend" />
          </div>
        </VCard>
        <VRow>
          <VCol cols="12">
            <VFadeTransition>
              <SizeHoursDataRow
                :chosenDate="chosenDate"
                :total="totalSend"
                v-if="permissions.can_see_sizy_hours_data"
                :userPlanInfo="userPlanInfo"
              />
            </VFadeTransition>
            <GenerateReports
              v-if="permissions.can_see_client_reports"
              @showReportDialog="visibleClientListReport = true"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <ItemList :chosenDate="chosenDate" v-if="permissions.client && showData" />
    <ClientListReportDialog
      @showReportDialog="showReportDialog"
      :chosenDate="chosenDate"
      :visible="visibleClientListReport"
      @close="visibleClientListReport = false"
      v-if="permissions.can_see_client_reports && visibleClientListReport"
    />
    <ClientReportDialog
      :userUuid="userUuid"
      :chosenDate="chosenReportDate"
      :visible="visibleReportDialog"
      @close="onCLoseReportDialog"
      v-if="permissions.can_see_client_reports && visibleReportDialog"
    />
  </div>
</template>

<script>
import metricsService from "../../services/request/metrics/metricsService";
import DeliveredProducts from "../charts/DeliveredProducts.vue";
import SizeHoursDataRow from "./SizeHoursDataRow.vue";
import DateSortedField from "./DateSortedField.vue";
import GenerateReports from "./GenerateReports.vue";
import ClientListReportDialog from "./ClientListReportDialog.vue";
import ClientReportDialog from "./ClientReportDialog.vue";
import ManagersChart from "./ManagersChart.vue";
import CurrentSize from "./CurrentSize.vue";
import ItemList from "./ItemList.vue";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import loader from "../../mixins/loader";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import language from "../../mixins/language";
import {mapActions, mapGetters} from 'vuex';
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";

export default {
  name: "CabinetComponent",
  mixins: [user, notifications, loader, language],
  data: () => ({
    showData: false,
    stateType: "",
    loading: false,
    id_state: 430,
    metricsStates: [],
    deportSizeHours: [],
    deportSizeCurrent: [],
    chosenDate: "",
    total: "",
    visibleClientListReport: false,
    visibleReportDialog: false,
    userUuid: "",
    userPlanInfo: {},
    loadTime: 0
  }),
  async mounted() {
    EventBus.$on('deportCurrentSizeChange', async (data) => {
      // orderDialog('params', data);
      this.showData = data;
    });
    if (this.permissions.client) {
      this.getPaymentPlan();
    }
    await this.getMetricsDeliveriesStates();
    this.checkLoadTime();
    if(this.metricsStates.length) {
      this.showData = true;
    }
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ]),
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    totalSend() {
      if (this.userPlanInfo && this.total) {
        return this.total;
      } else {
        return 0;
      }
    }
  },
  watch: {
    total: {
      deep: true,
      handler(e) {}
    }
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    onCheckState(idState) {
      const outState = [400, 401, 410, 411, 412, 420, 430, 440, 450, 490];
      if (outState.includes(Number(idState))) {
        return "out";
      } else {
        return "in";
      }
    },
    onCLoseReportDialog() {
      this.visibleReportDialog = false;
      this.userUuid = "";
    },
    onTotalSend(e) {
      this.total = e.substr(7);
    },
    showReportDialog(e) {
      // orderDialog(e);
      this.visibleReportDialog = true;
      this.userUuid = e.uuid;
      this.chosenReportDate = e.date;
      // orderDialog(this.chosenReportDate);
    },
    async getMetricsDeliveriesStates() {
      try {
        this.setLoading(true);
        this.metricsStates = await metricsService.getMetricsDeliveriesStates();
        this.metricsStates.forEach(item => {
          const stateType = this.onCheckState(item.id_state);
          item.stateType = stateType;
        });
        // orderDialog(this.metricsStates);
        // orderDialog("this.metricsStates", this.metricsStates);
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async getPaymentPlan() {
      try {
        if (this.getUser.uuid_user_plan) {
          this.loading = true;
          const planItem = await paymentPlansService.usersPlansInfo(this.getUserProperty('uuid_user_plan'));
          this.userPlanInfo = planItem;
          this.loading = false;
        }
      } catch (e) {
        //
        this.setErrorNotification(this.$t("paymentPlan.withoutPaymentPlan"));
      }
    },
    onChangeDate(e) {
      this.chosenDate = e;
    }
  },
  components: {
    DeliveredProducts,
    SizeHoursDataRow,
    DateSortedField,
    CurrentSize,
    ItemList,
    GenerateReports,
    ClientListReportDialog,
    ClientReportDialog,
    ManagersChart
  }
};
</script>

<style lang="scss">
.section-metricsStates {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      padding: 2px 0;
      width: 100%;

      &__content {
        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        border: 1px solid #e3e3e3;
        transition: all 0.3s ease;

        &.active {
          background-color: rgba(79, 174, 59, 0.12);
          border: 1px solid #4fae3b;
          color: #2f7221;

          .item__count {
            background-color: #4fae3b;
            // color: #fff;
          }
        }

        &:hover {
          border: 1px solid #4fae3b;
          cursor: pointer;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      &__name {
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px 10px;
      }

      &__count {
        // background: rgb(223, 223, 223);
        min-width: 70px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &.sm {
          min-width: 50px;
        }
      }
    }
  }
}
.error-message-main {
  .v-btn {
    text-transform: unset;
  }
}
.cabinet-del-state-list {
  min-height: 25rem;
  max-height: 25rem;
  height: 25rem;
  overflow-y: scroll;
  &.clientCabinet {
    min-height: 517px;
    height: 517px;
    max-height: 517px;
  }
}
.metric-card {
  position: relative;
  min-height: 472px;
}
.empty-image {
  position: absolute;
  width: 80%;
  opacity: 0.05;
}
</style>
