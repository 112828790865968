<template>
  <VDialog
    v-model="visibility"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :height="500" :loading="!isDomLoaded">
      <template slot="progress">
        <v-progress-linear color="success" indeterminate></v-progress-linear>
      </template>
      <v-card-title class="pa-0 mb-2">
        <div class="header">
          <VContainer>
            <v-row aling="center">
              <img src="@/assets/images/logo-dark.svg" alt="" />
            </v-row>
          </VContainer>
        </div>
      </v-card-title>
      <v-card-subtitle>
        <v-row class="my-5 d-print-none ">
          <v-btn plain color="success" @click="onClose">
            <v-icon left>mdi-arrow-left</v-icon>{{ $t("btn.back") }}
          </v-btn>
        </v-row>
        <v-row class="mb-5" justify="space-between" align="center">
          <VCol>
            <h2 class="font-weight-bold">
              {{ $t("cabinet.clientReport") }}:
              <i>{{ user.name || "--" }} {{ user.surname || "--" }}</i>
            </h2>
          </VCol>
          <div>
            <VSubheader class="text-transform-capitalize">
              {{ DateToText }}
            </VSubheader>
          </div>
          <div class="mt-1 d-print-none aling-center d-flex">
            <v-btn color="primary" @click="printPage">
              <v-icon left>mdi-printer</v-icon>
              {{ $t("cabinet.print") }}
            </v-btn>
          </div>
        </v-row>
      </v-card-subtitle>
      <v-card-text v-if="isDomLoaded">
        <ns-data-table
          :headers="plansHeader"
          class="my-table supply"
          :request-service="getRequestService"
          custom-height="400px"
          item-class="getRowClass"
        >
          <template v-slot:item.price="{ item }">
            <span class="green--text font-weight-bold">
              {{ item.price }} {{ $t("paymentPlan.uah") }}
            </span>
          </template>
        </ns-data-table>
      </v-card-text>
    </VCard>
  </VDialog>
</template>

<script>
import usersService from "@/services/request/users/usersService";
import metricsService from "@/services/request/metrics/metricsService";
import notifications from "@/mixins/notifications";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import moment from "moment";
import { mapGetters } from 'vuex';
import EventBus from "../../events/EventBus";
import loader from "@/mixins/loader";
import NsDataTable from "@/components/UI/ns-data-table/ns-data-table.vue";

export default {
  components: { NsDataTable },
  mixins: [notifications, loader],
  data: () => ({
    user: [],
    date: new Date().toISOString().substr(0, 7),
    deportSizeHours: [],
    amount: "",
    totalSend: "",
    userPlanInfo: {},
    deportSizeCurrent: [],
    isDomLoaded: false,
    loadingStatus: {
      user: false,
      deportSizeCurrent: false,
      deportSizeHours: false,
      totalSend: false
    }
  }),
  props: {
    chosenDate: {
      require: false
    },
    userUuid: {
      require: true
    },
    visible: {
      require: true
    }
  },
  watch: {
    isDomLoaded: {
      handler(e) {
        if (e === true) {
          this.setLoading(false);
        }
      }
    },
    userUuid: {
      deep: true,
      handler(e) {

      }
    },
    user: {
      handler(e) {
        if (this.user.length) {
          EventBus.$emit("deportCurrentSizeChange", true);
        }
      }
    },
    chosenDate: {
      deep: true,
      handler(e) {
        if (e) {
          this.date = e;
        }
      }
    },
    loadingStatus: {
      handler(newStatus) {
        this.checkLoadingStatus();
      },
      deep: true
    }
  },
  async mounted() {
    this.setLoading(true);
    if (this.chosenDate) {
      this.date = this.chosenDate;
    }
    await this.getUser();
    await this.getMetricsDeportSizeCurrent();
    await this.getMetricsDeportSizeHours();
    await this.getSendDeliveries();
    this.$nextTick(() => {
      setTimeout(() => {
        this.isDomLoaded = true;
      }, 100);
    });
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    deportCurrentSize() {
      if (this.deportSizeCurrent?.m) {
        return this.deportSizeCurrent?.m.toFixed(2)
      } else {
        return '0'
      }
    },
    ...mapGetters([
      "currentLanguage"
    ]),
    DateToText() {
      return moment(this.date)
        .locale(this.currentLanguage.key)
        .format(" MMMM YYYY");
    },
    getTotalPrice() {
      if (this.getTotalSendPrice && this.getTotalHoursPRice) {
        return this.getTotalSendPrice + this.getTotalHoursPRice;
      } else {
        return 0;
      }
    },
    getTotalSendPrice() {
      if (this.totalSend && this.userPlanInfo.price_delivery) {
        return Number(this.totalSend) * Number(this.userPlanInfo.price_delivery);
      }
      return 0;
    },
    getTotalHoursPRice() {
      if (this.deportSizeHours.h_m && this.userPlanInfo.price_hour_meter) {
        return +(
          Number(this.deportSizeHours.h_m) * Number(this.userPlanInfo.price_hour_meter)
        ).toFixed(2);
      } else {
        return 0;
      }
    },
    getRequestService() {
      return this.getData
    },
    plansHeader() {
      const result = [
        {
          text: this.$t("table.description"),
          value: "description",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("table.numbers")}`,
          value: "numbers",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.price")}`,
          value: "price",
          align: "center",
          sortable: false
        },
      ];
      return result;
    },
  },
  methods: {
    async getData() {
      return [
        {
          description: this.$t("cabinet.sending"),
          numbers: `${this.totalSend || "0"}`,
          price: `${this.getTotalSendPrice}`,
        },
        {
          description: this.$t("cabinet.amountSpaceStorage"),
          numbers: `${this.deportSizeHours?.h_m || "0"} ${this.$t('products.meter')}³ / ${this.$t('products.hour')}`,
          price: `${this.getTotalHoursPRice}`,
        },
        {
          description: this.$t("cabinet.currentStorageCapacity"),
          numbers: `${this.deportCurrentSize || "0"} ${this.$t('products.meter')}³`,
          price: '0',
        },
        {
          description: this.$t("cabinet.total"),
          numbers: '',
          price: `${this.getTotalPrice || "0"}`,
          class: 'bold-text'
        },
      ]
    },
    async getMetricsDeportSizeCurrent() {
      try {
        if (this.user?.uuid) {
          this.deportSizeCurrent = await metricsService.getMetricsDeportSizeCurrentForClient(this.user.uuid);
        }
        this.loadingStatus.deportSizeCurrent = true;
      } catch (e) {
        console.log(e)
      }
    },
    onClose() {
      this.$emit("close");
      this.user = [];
    },
    printPage() {
      window.print();
    },
    async getUser() {
      try {
        const newUser = await usersService.getUserByUUID(this.userUuid);
        if (!newUser) {
          // eslint-disable-next-line
          throw "NO USER FOUND"
        }
        this.user = newUser[0];
        this.loadingStatus.user = true;
        this.getPaymentPlan(this.user);
      } catch (e) {
        console.log(e)
      }
    },
    async getPaymentPlan(user) {
      try {
        if (user.uuid_user_plan) {
          this.loading = true;
          const planItem = await paymentPlansService.usersPlansInfo(user.uuid_user_plan);
          if (!planItem) {
            this.setErrorNotification("Цей клієнт поки немає тарифного плану");
          } else {
            this.userPlanInfo = planItem;
          }
          this.loading = false;
        }
      } catch (e) {
        this.setErrorNotification(this.$t('paymentPlan.no_payment_plan'));
      }
    },
    async getMetricsDeportSizeHours() {
      try {
        const params = [];
        params.year = this.date.substr(0, 4);
        params.month = this.date.substr(5, 2);
        params.uuid_deport = this.user.uuid_deport;
        params.uuid_user = this.user.uuid;
        this.deportSizeHours = await metricsService.getMetricsDeportSizeHours(params);

        console.log("h_m", this.deportSizeHours.h_m)

        if (this.deportSizeHours.h_m) {
          this.amount = (this.deportSizeHours.h_m * 0.2).toFixed(2);
          this.amount = parseFloat(this.amount, 10);
        } else {
          this.amount = null;
        }
        this.loadingStatus.deportSizeHours = true;
      } catch (e) {
        console.log(e)
      }
    },
    async getSendDeliveries() {
      try {
        const params = [];
        params.id_state = 412;
        params.year = this.date.substr(0, 4);
        params.month = this.date.substr(5, 2);
        params.uuid_user = this.user.uuid;
        const deliveries = await metricsService.getMetricsDeliveriesDelivered(params);
        this.totalSend = deliveries.extra.total;
        console.log("total", this.totalSend)
        this.loadingStatus.totalSend = true;
      } catch (e) {
        console.log(e)
      }
    },
    checkLoadingStatus() {
      if (this.loadingStatus.user &&
        this.loadingStatus.deportSizeCurrent &&
        this.loadingStatus.deportSizeHours &&
        this.loadingStatus.totalSend) {
        this.isDomLoaded = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background: #676767;
  padding: 10px 0;
}
@media print {
  .container {
    padding: 10px 20px !important;
  }
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.bold-text {
  font-weight: bold;
}
</style>
